
import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'; 
import HomePage from './HomePage';
import FiltersPage from './FiltersPage';
import LoginPage from './LoginPage';
import WelcomePage from './WelcomePage'; 
import TelegramVerifyPage from './TelegramVerifyPage';
import SignUpPage from './SignUpPage';
import ProfilePage from './ProfilePage';
import AdminPage from './AdminPage';
import AdminConsole from './AdminConsole';
import AdminUpwork from './AdminUpwork';
import UpworkForm from './AdminUpworkForm';
import ProductsPage from './ProductsPage';

function App() {
  return (
      <BrowserRouter>
          <Routes>
              <Route path='/api/filters/get' element={<Navigate to="/filters" />} /> 
              <Route path='/' element={<HomePage />} />
              <Route path='/filters' element={<FiltersPage />} />
              <Route path='/products' element={<ProductsPage />} />
              <Route path='/login' element={<LoginPage />} />
              <Route path="/welcome" element={<WelcomePage />} />
              <Route path="/verify/telegram" element={<TelegramVerifyPage />} />
              <Route path='/signup' element={<SignUpPage />} />
              <Route path='/profile' element={<ProfilePage />} />
              <Route path='/admin' element={<AdminPage />} />
              <Route path='/admin/console' element={<AdminConsole />} />
              <Route path='/admin/upwork' element={<AdminUpwork />} />
              <Route path='/admin/upwork/form' element={<UpworkForm />} />
          </Routes> 
      </BrowserRouter>
  );
} 

export default App;
