import React, { useState, useEffect } from 'react';
import { InputGroup, Accordion, Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import axios from 'axios';

function UpworkForm({upworkFilterData}) { // Renamed from AdminUpworkForm to UpworkForm

    const [errorMessage, setErrorMessage] = useState(null);
    const [upworkFilterId, setUpworkFilterId] = useState(null);
    const [filters, setFilters] = useState({
        upworkFilterName: 'Upwork filter',
        clientId: null,
        searchTerms: null,
        anyOfWords: null,
        noneOfWords: null,
        exactPhrase: null,
        titleSearch: null,
        skills: null,
        location: null,
        hourlyPrice: null,
        fixedPrice: null
    });

    useEffect(() => {
        if(upworkFilterData){
            setFilters({
                upworkFilterName: upworkFilterData.upworkFilterName || null,
                clientId: upworkFilterData.clientId || null,
                searchTerms: upworkFilterData.searchTerms || null,
                anyOfWords: upworkFilterData.anyOfWords || null,
                noneOfWords: upworkFilterData.noneOfWords || null,
                exactPhrase: upworkFilterData.exactPhrase || null,
                titleSearch: upworkFilterData.titleSearch || null,
                skills: upworkFilterData.skills || null,
                location: upworkFilterData.location || null,
                hourlyPrice: upworkFilterData.hourlyPrice || null,
                fixedPrice: upworkFilterData.fixedPrice || null
            });
            setUpworkFilterId(upworkFilterData.upworkFilterId);
        }
    },[upworkFilterData]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFilters(prevUpworkFilters => ({
            ...prevUpworkFilters, 
            [name]: value,
        }));
    };

    function checkPrice(field, maxValue, message, errorCount) {
        console.log(`Number value: ${Number(field)}`);
        console.log(`maxValue: ${maxValue}`);
        if (field !== '') {
            if (Number(field) < 0 || Number(field) > maxValue) {
                setErrorMessage(message);
                errorCount += 1;
            } else if (isNaN(Number(field))) {
                setErrorMessage(`Number not provided`);
                errorCount += 1; // Increment errorCount and return it
            }
        }
        return errorCount;
    }
    

    const validateForm = () => {
        setErrorMessage(null);
        let errorCount = 0;

        if (filters.upworkFilterName === ''){
            errorCount += 1;
            setErrorMessage(`Please provide filter name`);
        }

        errorCount = checkPrice(filters.hourlyPrice, 999.99, `Hourly price too small or too large`, errorCount);
        errorCount = checkPrice(filters.fixedPrice, 99999.99, `Fixed price too small or too large`, errorCount);
        console.log(errorCount);
        if (errorCount === 0) {
            handleSubmit();
        }
    }

    const handleSubmit = async() => {
        try{
            if (upworkFilterId === null){
                const response = await axios.post(`/admin/new/upwork/filter`, {filters});
                setUpworkFilterId(response.data.upworkFilterId);
            }
            else {
                await axios.put(`/admin/update/upwork/filter/${upworkFilterId}`, {filters});
            }
        } catch(error){
            console.error(error);
        }
    }

    return (
        <main>
            <div>
                <div className="container mt-2">
                    <Form>
                        <Accordion.Item eventKey={upworkFilterId == null ? 0 : upworkFilterId}>
                            <Accordion.Header>{filters.upworkFilterName}</Accordion.Header>
                            <Accordion.Body>

                                <div className='mb-1'>
                                    <Form.Label className='mb-0 mt-2'>Filter name</Form.Label>
                                    <Form.Control name='upworkFilterName' value={filters.upworkFilterName} onChange={handleChange}/>
                                </div>

                                <div className='mb-1'>
                                    <Form.Label className='mb-0 mt-2'>Client ID</Form.Label>
                                    <Form.Control name='clientId' value={filters.clientId} onChange={handleChange}/>
                                </div>

                                <Form.Label className='mb-0 mt-2'>Search Terms</Form.Label>
                                <InputGroup className="mb-1">
                                    <Form.Control name='searchTerms' value={filters.searchTerms} onChange={handleChange}/>
                                    <OverlayTrigger placement="top" overlay={
                                            <Tooltip id="search-tooltip">
                                                Search terms should be seperated by ";"
                                            </Tooltip>
                                    }>
                                        <InputGroup.Text id="search-seperator">; Seperator</InputGroup.Text>
                                    </OverlayTrigger>
                                </InputGroup>

                                <Form.Label className='mb-0 mt-2'>Any of these words</Form.Label>
                                <InputGroup className="mb-1">
                                    <Form.Control name='anyOfWords' value={filters.anyOfWords} onChange={handleChange}/>
                                    <OverlayTrigger placement="top" overlay={
                                            <Tooltip id="any-tooltip">
                                                This advanced filter should be seperated by ","
                                            </Tooltip>
                                    }>
                                        <InputGroup.Text id="any-seperator">, Seperator</InputGroup.Text>
                                    </OverlayTrigger>
                                </InputGroup>

                                <Form.Label className='mb-0 mt-2'>None of these words</Form.Label>
                                <InputGroup className="mb-1">
                                    <Form.Control name='noneOfWords' value={filters.noneOfWords} onChange={handleChange}/>
                                    <OverlayTrigger placement="top" overlay={
                                            <Tooltip id="none-tooltip">
                                                This advanced filter should be seperated by ","
                                            </Tooltip>
                                    }>
                                        <InputGroup.Text id="none-seperator">, Seperator</InputGroup.Text>
                                    </OverlayTrigger>
                                </InputGroup>

                                <Form.Label className='mb-0 mt-2'>Exact phrase</Form.Label>
                                <InputGroup className="mb-1">
                                    <Form.Control name='exactPhrase' value={filters.exactPhrase} onChange={handleChange}/>
                                    <OverlayTrigger placement="top" overlay={
                                            <Tooltip id="phrase-tooltip">
                                                This advanced filter should be seperated by ","
                                            </Tooltip>
                                    }>
                                        <InputGroup.Text id="phrase-seperator">, Seperator</InputGroup.Text>
                                    </OverlayTrigger>
                                </InputGroup>

                                <Form.Label className='mb-0 mt-2'>Title search</Form.Label>
                                <InputGroup className="mb-1">
                                    <Form.Control name='titleSearch' value={filters.titleSearch} onChange={handleChange}/>
                                    <OverlayTrigger placement="top" overlay={
                                            <Tooltip id="title-tooltip">
                                                This advanced filter should be seperated by ","
                                            </Tooltip>
                                    }>
                                        <InputGroup.Text id="title-seperator">, Seperator</InputGroup.Text>
                                    </OverlayTrigger>
                                </InputGroup>

                                <Form.Label className='mb-0 mt-2'>Skills</Form.Label>
                                <InputGroup className="mb-1">
                                    <Form.Control name='skills' value={filters.skills} onChange={handleChange}/>
                                    <OverlayTrigger placement="top" overlay={
                                            <Tooltip id="skills-tooltip">
                                                This field should be seperated by ";". Before entering, make sure this skill exists in UpWork. Leave no grammatical errors.
                                            </Tooltip>
                                    }>
                                        <InputGroup.Text id="skills-seperator">; Seperator</InputGroup.Text>
                                    </OverlayTrigger>
                                </InputGroup>

                                <div className='mb-1'>
                                    <Form.Label className='mb-0 mt-2'>Location</Form.Label>
                                    <Form.Control name='location' value={filters.location} onChange={handleChange}/>
                                </div>

                                <div className='mb-1'>
                                    <Form.Label className='mb-0 mt-2'>Hourly price</Form.Label>
                                    <Form.Control name='hourlyPrice' value={filters.hourlyPrice} onChange={handleChange}/>
                                </div>

                                <div className='mb-1'>
                                    <Form.Label className='mb-0 mt-2'>Fixed price</Form.Label>
                                    <Form.Control name='fixedPrice' value={filters.fixedPrice} onChange={handleChange}/>
                                </div>

                                <Button variant="primary" className='mt-2 w-100' onClick={validateForm}>
                                    Save
                                </Button>
                                {errorMessage && <div className="error-message text-center">{errorMessage}</div>}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Form>
                </div>
            </div>
        </main>
    );
}

export default UpworkForm; // Exporting UpworkForm as default
