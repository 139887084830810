import React, { useState, useEffect } from 'react';
import NavigationBar from './NavigationBar';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './styles/profile.scss';

function ProfilePage() {

    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');

    useEffect(() => {
        getUser();
    }, []);

    const getUser = () => {
        axios({
            method: "get",
            withCredentials: true,
            url: "/api/get/user"
        })
        .then(res => {
            const userData = res.data; // Store the entire response data
            setEmail(userData.email);
            setRole(userData.role);   
          })
          .catch(err => console.log(err));
    }

    return (
        <div>
            <NavigationBar />
            <Container fluid id='profile-container'>
                <Card>
                    <Card.Body>
                        <div className='profile-title'>PROFILE</div>
                        <Card.Title>Email</Card.Title>
                        <span className='email-underline'></span>
                        <Card.Text>{email}</Card.Text>
                        <Card.Title>Current plan</Card.Title>
                        <span className='plan-underline'></span>
                        <Card.Text>{role}</Card.Text>
                        <Card.Title>Benefits of current Plan</Card.Title>
                        <span className='info-underline'></span>
                        {role === 'user' && (
                            <Card.Text>`Regular User` plan doesn't have any benefits nor functionality.</Card.Text>
                        )}
                        {role === 'basic' && (
                            <Card.Text>`Basic` plan lets you create and use up to 5 SS filters.</Card.Text>
                        )}
                        {role === 'advanced' && (
                            <Card.Text>`Advanced` plan lets you create and use up to 10 SS filters.</Card.Text>
                        )}
                        {role === 'admin' && (
                            <Card.Text>`Experienced` plan lets you create and use up to 20 SS filters.</Card.Text>
                        )}
                        <Row>
                            <Col xs={12} lg={8} id='products-btn-con'>
                                <Button
                                    variant="primary"
                                    as={Link}
                                    to="/products"
                                    className="w-100" id='btn-products'>
                                    Products
                                </Button>
                            </Col>
                            <Col xs={12} lg={4} id='signout-btn-con'>
                                <Button
                                    variant="danger"
                                    as={Link}
                                    to="/auth/signout"
                                    className="w-100" id='btn-signout'>
                                    Sign Out
                                </Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
}

export default ProfilePage;
