import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import NavigationBar from './NavigationBar';
import './styles/home.scss';
import scheme from './img/scheme-white.svg';

function HomePage() {

    const productSchemeRef = useRef(null);
    const [opacity, setOpacity] = useState(0);

    useEffect(() => {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              const { intersectionRatio, target } = entry;
              const visibleHeight = target.clientHeight * intersectionRatio;
              const newOpacity = visibleHeight / target.clientHeight;
              setOpacity(newOpacity);
            });
          },
          {
            threshold: Array.from({ length: 101 }, (_, i) => i / 100), // Create an array of thresholds from 0 to 1
          }
        );
    
        if (productSchemeRef.current) {
          observer.observe(productSchemeRef.current);
        }
    
        return () => {
          if (productSchemeRef.current) {
            observer.unobserve(productSchemeRef.current);
          }
        };
      }, []);

    return (
        <div>
            <NavigationBar />
            <div id='home-div'>
                <div className="main-page">
                    <Row>
                        <Col xs={12} lg={6} className="left-column">
                        <div className="text-container" id='main-text1'>
                            <p>Save your time</p>
                        </div>
                        <div className="text-container" id='main-text2'>
                            <p>Receive your desired deals</p>
                            <p>instantly as soon as they appear.</p>
                        </div>
                        <div className="text-container" id='main-text3'>
                            <p>Customize your filters,</p>
                            <p>receive tailored deals directly in your inbox,</p>
                            <p>and be the first to take advantage of them.</p>
                        </div>
                        <div className="button-container">
                            <Button variant="outline-light" id='main-button' as={Link} to='/signup'>Sign Up</Button>
                        </div>
                        </Col>
                        <Col xs={12} lg={6} className="right-column">
                        <div className="title">
                            <h2>Product Scheme</h2>
                        </div>
                        <img src={scheme} alt="Sample" className="right-aligned-image"  ref={productSchemeRef} style={{ opacity }} />
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default HomePage;