import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './styles/telegramVerify.scss';

const TelegramVerifyPage = () => {
    const [code, setCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();

    const handleVerify = async () => {
        try {
            const response = await axios.post('/api/verify/telegram', { code });
            if (response.data.success) {
                setSuccessMessage('Verification successful! Redirecting...');
                setTimeout(() => {
                    navigate('/welcome');
                }, 2000); // Keep the success message visible for 2 seconds
            } else {
                setErrorMessage(response.data.message || 'Invalid or expired code. Please try again.');
            }
        } catch (error) {
            setErrorMessage('An error occurred. Please try again later.');
        }
    };

    return (
        <div className="telegram-verification-container">
            <h2>Verify Your Telegram Account</h2>
            <p>Follow the steps below to verify your Telegram account:</p>
            <ol>
                <li>Download Telegram on your <a href="https://telegram.org" target="_blank" rel="noopener noreferrer">mobile or computer</a> or open <a href="https://web.telegram.org" target="_blank" rel="noopener noreferrer">Telegram Web</a>.</li>
                <li>Log into your Telegram account.</li>
                <li>Search for <strong>StalkerWindBOT</strong> and send the command <code>/verify</code>.</li>
                <li>You will receive a code, which you need to input below:</li>
            </ol>
            <div className="input-container">
                <label htmlFor="verificationCode"></label>
                <input 
                    type="text" 
                    id="verificationCode" 
                    value={code} 
                    onChange={(e) => setCode(e.target.value)} 
                    placeholder="Enter the code you received"
                />
            </div>

            <button onClick={handleVerify}>Verify</button>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            {successMessage && <p className="success-message">{successMessage}</p>}
        </div>
    );
};

export default TelegramVerifyPage;
