import React, { useState, useEffect } from 'react';
import { Container, Button, Accordion, Alert } from 'react-bootstrap';
import NavigationBar from './NavigationBar';
import UpworkForm from './AdminUpworkForm';
import axios from 'axios';

function AdminUpwork() {

    const [upworkFilters, setUpworkFilters] = useState([]);
    const [upworkForms, setUpworkForms] = useState([]);
    const [role, setRole] = useState('');

    const getUpworkFilters = async() => {
        try{
            const response = await axios.get(`/admin/get/upwork/filters`);
            const upworkFiltersData = response.data;
            const formattedUpworkFilters = Object.keys(upworkFiltersData).map(upworkFilterId => ({
                upworkFilterId,
                ...upworkFiltersData[upworkFilterId][0] // Assuming each upworkFilterId only has one filter group
            }));
            console.log(formattedUpworkFilters);
            if (formattedUpworkFilters.length !== 0){
                setUpworkFilters(formattedUpworkFilters); // Set the state with the fetched filters
            }
        } catch (error) {
            console.error('Error fetching upwork filters:', error);
        }
    };

    const getUser = async () => {
        try{
            const response = await axios.get(`/api/get/user`);
            setRole(response.data.role)
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    useEffect(() => {
        getUpworkFilters()
        .then(getUser)
            .catch(error => console.error('Error fetching data:', error));
    },[]);

    const handleAddNewFilter = () => {
        setUpworkForms([...upworkForms, {upworkFilters}]);
    }

    return (
        <main>
            <NavigationBar />
            <Container>
                <div className='text-center mb-5'>
                    <h1>Upwork Filters</h1>
                </div>
                <Accordion>
                    {/* Render each filter form */}
                    {(role === 'admin') && upworkFilters.map(filter => (
                        <UpworkForm key={filter.id} upworkFilterData={filter} /> 
                    ))}


                    {upworkForms.map((form) => ( // Crete new form
                        <UpworkForm key={form.id} /> 
                    ))}
                </Accordion>
                
                <div className="text-center">
                    <Button className='m-3' variant='success' onClick={handleAddNewFilter}>+ Add new filter</Button>
                </div>

                
            </Container>

        </main>
    );
}

export default AdminUpwork;