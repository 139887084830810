import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import NavigationBar from './NavigationBar';
import axios from 'axios';
import validator from 'validator'; 
import './styles/authForm.scss';

function SignUpPage() {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        email: '',
        password: '',
        repeatPassword: ''
    });

    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [isRegistering, setIsRegistering] = useState(false);

    // Email validation
    const validateEmail = (email) => validator.isEmail(email);

    // Password validation (at least 8 characters)
    const validatePassword = (password) => validator.isStrongPassword(password, {
        minLength: 8,
        minLowercase: 0,
        minUppercase: 0,
        minNumbers: 0,
        minSymbols: 0
    });

    // Password match validation
    const validateRepeatPassword = (password, repeatPassword) => password === repeatPassword;

    // Handle form changes
    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    // Handle registration

    // const handleRegister = async (e) => {
    //     e.preventDefault(); 
    //     const newErrors = {};
    
    //     // Validation
    //     if (!formData.email) {
    //         newErrors.email = 'Email is required.';
    //     } else if (!validateEmail(formData.email)) {
    //         newErrors.email = 'Please enter a valid email address.';
    //     }
    
    //     if (!formData.password) {
    //         newErrors.password = 'Password is required.';
    //     } else if (!validatePassword(formData.password)) {
    //         newErrors.password = 'Password must be at least 8 characters long.'; 
    //     }
    
    //     if (!formData.repeatPassword) {
    //         newErrors.repeatPassword = 'Please confirm your password.';
    //     } else if (!validateRepeatPassword(formData.password, formData.repeatPassword)) {
    //         newErrors.repeatPassword = 'Passwords must match.';
    //     }
    
    //     if (Object.keys(newErrors).length > 0) {
    //         setErrors(newErrors);
    //     } else {
    //         setIsRegistering(true);
    //         try {
    //             const response = await axios.post('/auth/register', formData, {
    //                 headers: { 
    //                     'Content-Type': 'application/json'
    //                 }
    //             });
    
    //             if (response.status === 201) {
    //                 // Log in the user after successful registration
    //                 const loginResponse = await axios.post('/auth/login', {
    //                     email: formData.email,
    //                     password: formData.password
    //                 }, {
    //                     headers: { 
    //                         'Content-Type': 'application/json'
    //                     }
    //                 });
    
    //                 // if (loginResponse.status === 200) {
    //                 //     setIsRegistering(false);
    //                 //     navigate('/welcome'); // Navigate to the welcome page on success
    //                 // }
    //                 if (loginResponse.status === 200) {
    //                     setIsRegistering(false);
    //                     navigate('/verify/telegram'); // Redirect to Telegram verification page on success
    //                 }
    //             }
    //         } catch (error) {
    //             setIsRegistering(false);
    //             if (error.response) {
    //                 switch (error.response.status) {
    //                     case 400:
    //                         setErrorMessage('An account with this email already exists.');
    //                         break;
    //                     default: 
    //                         setErrorMessage('Registration failed. Please try again.');
    //                 }       
    //             } else {
    //                 setErrorMessage('An unexpected error occurred. Please try again later.');
    //             }
    //         }
    //     }
    // };
    
const handleRegister = async (e) => {
    e.preventDefault(); 
    const newErrors = {};
    
    // Validation
    if (!formData.email) {
        newErrors.email = 'Email is required.';
    } else if (!validateEmail(formData.email)) {
        newErrors.email = 'Please enter a valid email address.';
    }
    
    if (!formData.password) {
        newErrors.password = 'Password is required.';
    } else if (!validatePassword(formData.password)) {
        newErrors.password = 'Password must be at least 8 characters long.'; 
    }
    
    if (!formData.repeatPassword) {
        newErrors.repeatPassword = 'Please confirm your password.';
    } else if (!validateRepeatPassword(formData.password, formData.repeatPassword)) {
        newErrors.repeatPassword = 'Passwords must match.';
    }
    
    if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
    } else {
        setIsRegistering(true);
        try {
            const response = await axios.post('/auth/register', formData, {
                headers: { 
                    'Content-Type': 'application/json'
                }
            });
    
            if (response.status === 201) {
                // Redirect to Telegram verification page after successful registration
                navigate('/verify/telegram');
            }
        } catch (error) {
            setIsRegistering(false);
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setErrorMessage('An account with this email already exists.');
                        break;
                    default: 
                        setErrorMessage('Registration failed. Please try again.');
                }       
            } else {
                setErrorMessage('An unexpected error occurred. Please try again later.');
            }
        }
    }
};

    return (
        <div>
            <NavigationBar />
            
            <div className="container-fluid" id="form-container">
                <div className="card">
                    <div className="card-body">
                        <h2 className="text-uppercase text-center" id="signup-title">Create an account</h2>
                        <form onSubmit={handleRegister}>
                            
                            {errorMessage && <div className="error-message">{errorMessage}</div>}

                            <div className="form-outline">
                                <label className="form-label">Email</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    name="email"
                                    value={formData.email} 
                                    onChange={handleChange} 
                                />
                                {errors.email && <p className="error-message">{errors.email}</p>}
                            </div>

                            <div className="form-outline">
                                <label className="form-label">Password</label>
                                <input 
                                    type="password" 
                                    className="form-control" 
                                    name="password"
                                    value={formData.password} 
                                    onChange={handleChange} 
                                />
                                {errors.password && <p className="error-message">{errors.password}</p>}
                            </div>

                            <div className="form-outline">
                                <label className="form-label">Confirm Password</label>
                                <input 
                                    type="password" 
                                    className="form-control" 
                                    name="repeatPassword"
                                    value={formData.repeatPassword} 
                                    onChange={handleChange}
                                />
                                {errors.repeatPassword && <p className="error-message">{errors.repeatPassword}</p>}
                            </div>

                            <div className="d-flex justify-content-center" id="btn-container">
                                <button 
                                    type="submit"
                                    className="btn" 
                                    id="btn-form-submit"
                                    disabled={isRegistering}
                                >
                                    {isRegistering ? 'Registering...' : 'Sign Up'}
                                </button>
                            </div>

                            <p className="text-center" id="footer-text">Already have an account?
                                <Link to="/login" className="nav-link"><u>Login here</u></Link>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignUpPage;
