import React, { useState, useEffect } from 'react';
import NavigationBar from './NavigationBar';
import { Container, Table, Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import './styles/adminConsole.scss';
import userDelete from './img/user_delete-white.svg';
import userDemote from './img/user_demote-white.svg';
import userPromote from './img/user_promote-white.svg';

function AdminConsole() {
    const [users, setUsers] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [selectedUserRole, setSelectedUserRole] = useState('');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [actionType, setActionType] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const getUsers = async () => {
        try{
            const response = await axios.get(`/admin/get/users`);
            const allUsers = response.data.allUsers;
            if (allUsers.length !== 0){
                setUsers(allUsers); // Set the state with the fetched filters
            }
            console.log(allUsers);
        } catch (error) {
            throw error;
        }
    }

    useEffect(() => {
        getUsers();

    
    }, []);

    const handleCloseConfirmationModal = () => {
        setShowConfirmationModal(false);
    };

    const handleConfirmation = () => {
        if (actionType === 'Delete') {
            deleteUser(selectedUserId);
        } else if (actionType === 'SetStalker') {
            setToStalker(selectedUserId);
        } else if (actionType === 'DemoteUser') {
            demoteToUser(selectedUserId);
        }
        setShowConfirmationModal(false);
    };

    const handleDeleteUser = (userId) => {
        setSelectedUserId(userId);
        setActionType('Delete');
        setShowConfirmationModal(true);
    };

    const setToStalker = (userId) => {
        console.log(`Set user with ID ${userId} to Stalker`);
        const setStalker = async () => {
            try{
                const response = await axios.put(`/admin/set/stalker/${userId}`);
                if (response.status === 200){
                    getUsers(); // fetch the page again.
                } else {
                    setErrorMessage(`Failed to set User ${userId} role to Stalker.`);
                }
            } catch (error) {
                setErrorMessage(`Failed to set User ${userId} role to Stalker.`);
                throw error;
            }
        }
        setStalker();
    };

    const handleSetToStalker = (userId, role) => {
        setSelectedUserId(userId);
        setSelectedUserRole(role);
        setActionType('SetStalker');
        setShowConfirmationModal(true);
    };

    const demoteToUser = (userId) => {
        console.log(`Demote user with ID ${userId} to User`);
        const demoteUser = async () => {
            try{
                const response = await axios.put(`/admin/set/user/${userId}`);
                if (response.status === 200){
                    getUsers(); // fetch the page again.
                } else {
                    setErrorMessage(`Failed to set User ${userId} role to User.`);
                }
            } catch (error) {
                setErrorMessage(`Failed to set User ${userId} role to User.`);
                throw error;
            }
        }
        demoteUser();
    };

    const handleDemoteToUser = (userId, role) => {
        setSelectedUserId(userId);
        setSelectedUserRole(role);
        setActionType('DemoteUser');
        setShowConfirmationModal(true);
    };

    const deleteUser = (userId) => {
        console.log(`Delete user with ID ${userId}`);

        const delUser = async () => {
            try{
                const response = await axios.delete(`/admin/delete/user/${userId}`);
                if (response.status === 200){
                    getUsers(); // fetch the page again.
                } else {
                    setErrorMessage(`Failed to delete User ${userId}.`);
                }
            } catch (error) {
                setErrorMessage(`Failed to delete User ${userId}.`);
                throw error;
            }
        }
        delUser();
    };

    function getModalTitle() {
        switch (actionType) {
            case 'Delete':
                return 'Delete Confirmation';
            case 'SetStalker':
                return 'Promotion Confirmation';
            case 'DemoteUser':
                return 'Demotion Confirmation';
            default:
                return 'Confirmation';
        }
    }
    
    function getModalBody() {
        switch (actionType) {
            case 'Delete':
                return <p>Are you sure you want to <b>delete</b> user with ID <b>{selectedUserId}</b>?</p>;
            case 'SetStalker':
                return <p>Are you sure you want to <b>promote</b> user with ID <b>{selectedUserId}</b> to <b>Stalker</b>?</p>;
            case 'DemoteUser':
                return <p>Are you sure you want to <b>demote</b> user with ID <b>{selectedUserId}</b> from <b>{selectedUserRole}</b> to <b>User</b>?</p>;
            default:
                return null;
        }
    }
    
    function getActionButtonVariant() {
        switch (actionType) {
            case 'Delete':
                return 'danger';
            case 'SetStalker':
                return 'success';
            case 'DemoteUser':
                return 'warning';
            default:
                return 'primary';
        }
    }
    
    function getActionButtonText() {
        switch (actionType) {
            case 'Delete':
                return 'Delete';
            case 'SetStalker':
                return 'Promote';
            case 'DemoteUser':
                return 'Demote';
            default:
                return 'Confirm';
        }
    }

    const renderConfirmationModal = () => {
        return (
            <Modal show={showConfirmationModal} onHide={handleCloseConfirmationModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{getModalTitle()}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {getModalBody()}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseConfirmationModal}>
                        Cancel
                    </Button>
                    <Button variant={getActionButtonVariant()} onClick={handleConfirmation}>
                        {getActionButtonText()}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    return (
        <main>
            <NavigationBar />
            <Container fluid id='admin-console-container'>
                <div className="page-title">Admin Console</div>
                <Table className='align-middle console-table'>
                    <thead className='text-center'> 
                        <tr>
                            <th scope="col">User ID</th>
                            <th scope="col">User Email</th>
                            <th scope="col">User Role</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody className='table-group-divider text-center'>
                        {users.map(user => (
                            <tr key={user.id}>
                                <th scope="row">{user.id}</th>
                                <td className='user-info'>{user.email}</td>
                                <td className='user-info'>{user.role}</td>
                                <td className='user-actions'>

                                {user.role === 'admin' && (
                                    <>
                                        <Button className='custom-button-delete' onClick={() => handleDeleteUser(user.id, user.role)}>
                                            <img src={userDelete} alt="Delete" className="button-icon" />
                                        </Button>
                                    </>
                                )}

                                {user.role === 'stalker' && (
                                    <>
                                        <Button className='custom-button-demote' onClick={() => handleDemoteToUser(user.id, user.role)}>
                                            <img src={userDemote} alt="Demote" className="button-icon" />
                                        </Button>
                                        <Button className='custom-button-delete' onClick={() => handleDeleteUser(user.id)}>
                                            <img src={userDelete} alt="Delete" className="button-icon" />
                                        </Button>
                                    </>
                                )}

                                {user.role === 'user' && (
                                    <>
                                        <Button className='custom-button-promote' onClick={() => handleSetToStalker(user.id, user.role)}>
                                            <img src={userPromote} alt="Promote" className="button-icon" />
                                        </Button>
                                        <Button className='custom-button-delete' onClick={() => handleDeleteUser(user.id, user.role)}>
                                            <img src={userDelete} alt="Delete" className="button-icon" />
                                        </Button>
                                    </>
                                )}
                                {errorMessage && <div className="error-message text-center">{errorMessage}</div>}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Container>
            {renderConfirmationModal()}
        </main>
    );
}

export default AdminConsole;
