import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './styles/welcomePage.scss';

function WelcomePage() {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch user data when component mounts
        const fetchUser = async () => {
            try {
                const response = await axios.get('/api/get/user', { withCredentials: true });
                setUser(response.data);
            } catch (error) {
                console.error('Error fetching user data:', error);
                navigate('/login'); // Redirect to login if fetching user data fails
            }
        };

        fetchUser();
    }, [navigate]);

    // Show a loading state while user data is being fetched
    if (!user) {
        return <p>Loading...</p>;
    }

    return (
        <div id="welcome-container">
            <div className="welcome-message">
                <p>Registration successful</p>
                <p>Welcome, {user.email}!</p>
            </div>
            <div className="button-container">
                <Link to="/profile" className="btn btn-primary">Profile</Link>
                <Link to="/products" className="btn btn-secondary">Products</Link>
            </div>
        </div>
    );
}

export default WelcomePage;
