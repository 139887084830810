import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import NavigationBar from './NavigationBar';
import { LinkContainer } from 'react-router-bootstrap';

function AdminPage() {
    return (
        <main>
            <NavigationBar />
            <Container className="py-5">
                <h1 className="text-center mb-4">Admin Page</h1>
                <Row>
                    <Col md={6} lg={4} className="mb-3">
                        <Card>
                            <Card.Body>
                                <Card.Title>Admin Console</Card.Title>
                                <Card.Text>
                                    Manage users and roles.
                                </Card.Text>
                                <LinkContainer to="/admin/console">
                                    <Button variant="primary">Go to Admin Console</Button>
                                </LinkContainer>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className="mb-3">
                        <Card>
                            <Card.Body>
                                <Card.Title>Admin Upwork</Card.Title>
                                <Card.Text>
                                    Manage tasks and projects.
                                </Card.Text>
                                <LinkContainer to="/admin/upwork">
                                    <Button variant="primary">Go to Admin Upwork</Button>
                                </LinkContainer>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </main>
    );
}

export default AdminPage;
